//
// Header Mobile
//




// Desktop mode
@include kt-desktop {
	.kt-header-mobile {
		display: none;
	}
}

// Tablet & mobile mode
@include kt-tablet-and-mobile {
	table[data-label="tablaGenerica"]{
		width: 680px;
	}

	table[data-label="tablaGenerica"] .MuiTableCell-head,
	table[data-label="tablaGenerica"] tbody .MuiTableCell-paddingNone{
		width: 90px!important;
		padding: 16px 0;
		text-align: center;
	}

	table[data-label="tablaGenerica"] tbody .MuiTableCell-paddingCheckbox{
		padding-left: 7px;
	}

	table[data-label="tablaGenerica"] thead tr th:nth-child(1),
	table[data-label="tablaGenerica"] thead tr th:last-child,
	table[data-label="tablaGenerica"] tbody tr td:nth-child(1),
	table[data-label="tablaGenerica"] tbody tr td:last-child{
		width: 50px!important;
	}

	table[data-label="tablaGenerica"] thead tr th:nth-child(3),
	table[data-label="tablaGenerica"] tbody tr td:nth-child(3),
	table[data-label="tablaGenerica"] thead tr th:nth-child(6),
	table[data-label="tablaGenerica"] tbody tr td:nth-child(6){
		width: 110px!important;
	}

	.MuiFormHelperText-root{
		font-size: 1rem!important;
		line-height: 1.3em!important;
	}

	.kt-header-mobile {
		display: flex;
		justify-content: space-between;
		padding: 0 kt-get($kt-page-padding, mobile);
		height: kt-get($kt-header-config, base, mobile, self, default, height);
		min-height: kt-get($kt-header-config, base, mobile, self, default, height);
		position: relative;
		z-index: 1;

		// Fixed
		.kt-header-mobile--fixed & {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex);
		}

		// Logo
		.kt-header-mobile__logo {
			max-width: 180px;
			display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: center;
            align-items: center;

            a{
            	max-width: 80%;
            }

            img{
            	max-width: 100%;
            }
		}

		// Toolbar
		.kt-header-mobile__toolbar {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include kt-burger-icon-build(kt-header-mobile__toggler, kt-get($kt-header-config, base, mobile, toolbar, toggler));
			@include kt-burger-icon-skin(kt-header-mobile__toggler, kt-get($kt-header-config, base, mobile, toolbar, toggler, color));

			.kt-header-mobile__toggler {
				margin: 0 1.5rem;

				& + .kt-header__topbar-item{
					width: 24px;
					height: 24px;
					padding: 3px 0 0;
					cursor: pointer;
				}
			}

			.kt-header__topbar-item{
				cursor: pointer;

				& + .kt-header__topbar-item{
					padding: 0 0 0 5px;
				}
			}

			.kt-header-mobile__topbar-toggler {
				@include kt-button-reset();
				display: flex;
				align-items: center;
				margin: 0 0.5rem 0 1rem;

				i {
					@include kt-transition();
					color: kt-get($kt-header-config, base, mobile, toolbar, toggler, color, default);
					font-size: 1.5rem;
					line-height: 0;
				}

				&.kt-header-mobile__topbar-toggler--active,
				&:hover {
					i {
						@include kt-transition();
						color: kt-get($kt-header-config, base, mobile, toolbar, toggler, color, active);
					}
				}
			}
		}
	}

	.kt-aside--on .kt-aside-close {
	    left: 240px!important;
	    top: 10px;
	}
}
